//* Imports
// Angular
import { Injectable } from '@angular/core';
// Rxjs
import { BehaviorSubject } from 'rxjs';
// Local Services
import { UserService } from '../userService/user.service';
import { ErrorService } from '../errorService/error.service';
import { WarrantDataService } from '../warrantDataService/warrant-data.service';
import { UtilityService } from '../utilityService/utility.service';
// Local Classes
import { NavigationItem } from 'src/app/classes/navigationItem/navigation-item';
import { User } from 'src/app/classes/user/user';
import { SearchWarrant } from 'src/app/classes/searchWarrant/search-warrant';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  //* Observables
  // Left Navigation Menu
  /** private navigation menu behavior subject */
  private _navMenu = new BehaviorSubject<NavigationItem[]>([]);
  /** private internal navigation menu to be manipulated */
  private navStore: NavigationItem[] = [];
  /** publicly subscribable navigation menu */
  readonly navMenu = this._navMenu.asObservable();

  // Profile Menu
  /** private profile menu behavior subject */
  private _profileMenu = new BehaviorSubject<NavigationItem[]>([]);
  /** private internal profile menu to be manipulated */
  private profileStore: NavigationItem[] = [];
  /** publicly subscribable profile menu */
  readonly profileMenu = this._profileMenu.asObservable();

  // Jump Menu
  /** private jump menu behavior subject */
  private _jumpMenu = new BehaviorSubject<NavigationItem[]>([]);
  /** private internal jump menu to be manipulated */
  private jumpStore: NavigationItem[] = [];
  /** publicly subscribable jump menu */
  readonly jumpMenu = this._jumpMenu.asObservable();

  // Jump Title
  /** private jump title behavior subject */
  private _jumpTitle = new BehaviorSubject<string>('');
  /** private internal jump menu to be manipulated */
  private jumpTitleStore = '';
  /** publicly subscribable jump menu */
  readonly jumpTitle = this._jumpTitle.asObservable();

  /** User observable */
  private user$: User;

  /** Warrant observable */
  private currentWarrant$: SearchWarrant;

  //* Naviation Items
  /**Default Destinations */
  private defaults = {
    nav: [
      {
        title: 'Dashboard',
        url: '/dashboard',
        icon: 'list',
      },
    ],
    profile: [
      {
        title: 'Profile',
        url: '/profile',
        icon: 'person',
      },
      {
        title: 'Logout',
        icon: 'log-out',
        action: 'logout',
      },
    ],
    jump: {
      warrant: [
        { section: 'preview', title: 'Preview', url: '/warrant/preview' },
        // eslint-disable-next-line prettier/prettier
        { section: 'probableCause', title: 'Probable Cause Statement', url: '/warrant/probable-cause' },
        { section: 'editWarrant', title: 'Strike Items', url: '/warrant/edit-warrant' },
        { section: 'publicNotes', title: 'Public Notes', url: '/warrant/public-notes' },
        { section: 'internalNotes', title: 'Internal Notes', url: '/warrant/internal-notes' },
        { section: 'courtTracking', title: 'Court Tracking', url: '/warrant/court-tracking' },
        { section: 'approve', title: 'Approve / Deny', url: '/warrant/approve' },
      ],
      order: [
        { section: 'preview', title: 'Preview', url: '/order/preview' },
        // eslint-disable-next-line prettier/prettier
        { section: 'probableCause', title: 'Probable Cause Statement', url: '/order/probable-cause' },
        { section: 'editWarrant', title: 'Strike Items', url: '/order/edit-warrant' },
        { section: 'publicNotes', title: 'Public Notes', url: '/order/public-notes' },
        { section: 'internalNotes', title: 'Internal Notes', url: '/order/internal-notes' },
        { section: 'courtTracking', title: 'Court Tracking', url: '/order/court-tracking' },
        { section: 'approve', title: 'Approve / Deny', url: '/order/approve' },
      ],
      gps: [
        { section: 'preview', title: 'Preview', url: '/gps/preview' },
        // eslint-disable-next-line prettier/prettier
        { section: 'probableCause', title: 'Probable Cause Statement', url: '/gps/probable-cause' },
        { section: 'editWarrant', title: 'Strike Items', url: '/gps/edit-warrant' },
        { section: 'publicNotes', title: 'Public Notes', url: '/gps/public-notes' },
        { section: 'internalNotes', title: 'Internal Notes', url: '/gps/internal-notes' },
        { section: 'courtTracking', title: 'Court Tracking', url: '/gps/court-tracking' },
        { section: 'approve', title: 'Approve / Deny', url: '/gps/approve' },
      ],
      specialized: [
        { section: 'preview', title: 'Preview', url: '/specialized/preview' },
        // eslint-disable-next-line prettier/prettier
        { section: 'probableCause', title: 'Probable Cause Statement', url: '/specialized/probable-cause' },
        { section: 'editWarrant', title: 'Strike Items', url: '/specialized/edit-warrant' },
        { section: 'publicNotes', title: 'Public Notes', url: '/specialized/public-notes' },
        { section: 'internalNotes', title: 'Internal Notes', url: '/specialized/internal-notes' },
        { section: 'courtTracking', title: 'Court Tracking', url: '/specialized/court-tracking' },
        { section: 'approve', title: 'Approve / Deny', url: '/specialized/approve' },
      ],
    },
  };

  //* Constructor
  constructor(
    private userService: UserService,
    private errorService: ErrorService,
    private warrantService: WarrantDataService,
    private utilityService: UtilityService
  ) {
    // Subscribe to user
    this.userService.user.subscribe(
      (data) => {
        this.user$ = data;

        // Reset if user is logged out
        if (this.user$.id != '' && this.user$.loggedIn) {
          this.setNavMenu(this.user$.loggedIn);
          this.setProfileMenu(this.user$.loggedIn);
        } else {
          this.resetNavMenu();
          this.resetProfileMenu();
          this.resetJumpMenu();
          this.resetJumpTitle();
        }

        // Publish to subscribers
        this.publishValues();
      },
      (error) => {
        this.errorService.handleError(error);
      }
    );

    // Subscribe to current warrant
    this.warrantService.currentWarrant.subscribe((newWarrant) => {
      // Set the local warrant to the new warrant
      this.currentWarrant$ = newWarrant;

      // Reset if the 'new' warrant is no warrant
      if (JSON.stringify(this.currentWarrant$) != '{}' && !!this.currentWarrant$.type) {
        this.setJumpMenu();
        this.setJumpTitle();
      } else {
        this.resetJumpMenu();
        this.resetJumpTitle();
      }

      // Publish to subscribers
      this.publishValues();
    });
  }

  //* Methods
  /**
   * Reset the nav menu to have no destinations
   */
  private resetNavMenu() {
    this.navStore = [];
  }

  /**
   * Sets Navigation Menu
   * @param loggedIn - if user is logged in
   */
  private setNavMenu(loggedIn: boolean): void {
    if (loggedIn) {
      this.navStore = this.defaults.nav;
    } else {
      this.resetNavMenu();
    }
  }

  /**
   * Reset Profile Menu
   */
  private resetProfileMenu(): void {
    this.profileStore = [];
  }

  /**
   * Sets Profile Menu
   * @param loggedIn - if user is logged in
   */
  private setProfileMenu(loggedIn: boolean): void {
    if (loggedIn) {
      this.profileStore = this.defaults.profile;
    } else {
      this.resetProfileMenu();
    }
  }

  /**
   * Reset the jump menu to have no destinations
   */
  private resetJumpMenu() {
    this.jumpStore = [];
  }

  /**
   * Add the appropriate jump menu options given a warrant type
   * and status
   */
  private setJumpMenu() {
    // Get document type for easy reference
    const docType = this.currentWarrant$.type;
    // Add additional menu options here

    // Initial
    let _jump = [];

    switch (docType) {
      case 'warrant':
        _jump = [...this.defaults.jump.warrant];
        break;
      case 'order':
        _jump = [...this.defaults.jump.order];
        break;
      case 'gps':
        _jump = [...this.defaults.jump.gps];
        break;
      case 'specialized':
        _jump = [...this.defaults.jump.specialized];
        break;
      default:
        break;
    }

    this.jumpStore = [..._jump];
    // End loading begun on WarrantDataService loadWarrantById()
    this.utilityService.endLoading();
  }

  /**
   * Reset the jump title store
   */
  private resetJumpTitle() {
    this.jumpTitleStore = '';
  }

  private setJumpTitle() {
    const { description, reportNumber, type } = this.currentWarrant$;
    let title = '';
    switch (type) {
      case 'warrant':
        title = 'Search Warrant';
        break;
      case 'order':
        title = 'Court Order for Identifying Characteristics';
        break;
      case 'gps':
        title = 'GPS';
        break;
      case 'specialized':
        title = 'Records';
        break;
    }
    this.jumpTitleStore = `${title}: ${description ? description : reportNumber}`;
  }

  // publish new values to subscribers
  publishValues() {
    this._navMenu.next(Object.assign([], this.navStore));
    this._profileMenu.next(Object.assign([], this.profileStore));
    this._jumpMenu.next(Object.assign([], this.jumpStore));
    this._jumpTitle.next(this.jumpTitleStore);
  }
}
